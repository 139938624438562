import { login, refresh, logout, authorize } from "@/services/auth";
import { setI18nLanguage } from "@/i18n";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    user: {},
    access_token: null,
    message: "",
    refreshing_token: false,
    refreshing_call: undefined,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getMessage(state) {
      return state.message;
    },
    getScope: (state) => (scope) => {
      if (!state.user.scopes) return false;
      if (state.user.master) return true;

      return state.user.scopes.find((item) => item == scope);
    },
    getIsMaster(state) {
      if (!state.user) return false;

      return state.user.master;
    },
    getRefreshToken() {
      return localStorage.getItem("token");
    },
    getAccessToken(state) {
      return state.access_token;
    },
  },
  mutations: {
    setAccessToken(state, payload) {
      state.access_token = payload;
      Vue.prototype.$http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload}`;
      Echo.connector.options.auth.headers[
        "Authorization"
      ] = `Bearer ${payload}`;
      Echo.connector.options.channelAuthorization.headers[
        "Authorization"
      ] = `Bearer ${payload}`;
    },
    setRefreshToken(state, payload) {
      localStorage.setItem("token", payload);
    },
    removeRefreshToken(state) {
      localStorage.removeItem("token");
    },
    fillToken(state, payload) {
      const raw_token = payload;
      const token_payload = raw_token.split(".")[1];
      if (!!!payload) context.commit("logout");
      const payload_json = atob(token_payload);
      const paylaod_data = JSON.parse(payload_json);
      state.user = paylaod_data;
      setI18nLanguage(state.user?.language);
    },
    removeAccessToken(state) {
      state.access_token = null;
      Vue.prototype.$http.defaults.headers.common["Authorization"] = ``;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async login(context, payload) {
      const encrypter = async function digestMessage(message) {
        const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join(""); // convert bytes to hex string
        return hashHex;
      };

      const code_verifier = Math.random().toString(36).substr(2, 20);
      const code_challenge = await encrypter(code_verifier);

      const response = await login(payload, code_challenge);

      if (response.success) {
        const authorization_token = response.authorization_token;
        const tokens = await authorize(authorization_token, code_verifier);
        context.commit("setRefreshToken", tokens.refresh_token);
        context.commit("setAccessToken", tokens.access_token);
        context.commit("fillToken", tokens.access_token);
      }

      return response;
    },
    refreshTokens(context) {
      const refresh_token = localStorage.getItem("token");
      if (!!!refresh_token) return false;

      if (context.state.refreshing_token) {
        return context.state.refreshing_call;
      }

      context.state.refreshing_token = true;
      const refreshing_call = refresh(refresh_token)
        .then((new_tokens) => {
          context.commit("setRefreshToken", new_tokens.refresh_token);
          context.commit("setAccessToken", new_tokens.access_token);
          context.commit("fillToken", new_tokens.access_token);
          context.state.refreshing_token = false;
          context.state.refreshing_call = undefined;
          return Promise.resolve(true);
        })
        .catch((error) => {
          context.commit("removeRefreshToken");
          console.error(error);
        });

      context.state.refreshing_call = refreshing_call;
      return refreshing_call;
    },
    async checkToken(context) {},
    refreshToken(context) {
      return new Promise(async (resolve, reject) => {
        const new_token = await refresh();
        context.commit("setToken", new_token);
        context.commit("fillToken", new_token);

        resolve(new_token);
      });
    },
    checkScope(context, scope) {
      if (!context.state.user.scopes) return false;

      if (context.state.user.is_master == true) return true;

      return context.state.user.scopes.find((item) => {
        return item == scope;
      });
    },
    async logout(context) {
      try {
        context.commit("setLoading", { loading: true }, { root: true });
        await logout(localStorage.getItem("token"));
        Echo.leaveChannel(`private-user.${context.state.user.sub}`);
        context.commit("removeRefreshToken");
        context.commit("setUser", {});
      } catch (error) {
        return false;
      } finally {
        context.commit("setLoading", { loading: false }, { root: true });
      }
    },
  },
};
